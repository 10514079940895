/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() { 
	    $('[data-youtube]').click(function () { 
		    var id = $(this).attr('data-youtube'); 
		    var src = '//www.youtube.com/embed/'+id+'?html5=1&autoplay=1&rel=0';
		    var iframe = '<iframe id="youtube" width="100%;" height="258px" class="yt_players" frameborder="0" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:258px;width:100%;top:0px;left:0px;right:0px;bottom:0px;z-index:999" src="'+src+'" allowfullscreen></iframe>';
		    $(this).find(".video-wrapper").html(iframe);
		    return false;
		});
	  	$('.panel-collapse').on('show.bs.collapse', function () {
	  		$(this).siblings('.panel-heading').addClass('active');
	  	});
	  	$('.panel-collapse').on('hide.bs.collapse', function () {
	  		$(this).siblings('.panel-heading').removeClass('active');
		});  
		function sticky_relocate() {
		    var window_top = $(window).scrollTop();
		    var div_top = $('#sticky-anchor').offset().top;
		    if (window_top > div_top) {
		        $('#sticky').addClass('stick');
		        $('#sticky-anchor').height($('#sticky').outerHeight());
		    } else {
		        $('#sticky').removeClass('stick');
		        $('#sticky-anchor').height(0);
		    }
		}
		$(function() {
		    $(window).scroll(sticky_relocate);
		    sticky_relocate();
		});
		var dir = 1;
		var MIN_TOP = 200;
		var MAX_TOP = 350;
		function autoscroll() {
		    var window_top = $(window).scrollTop() + dir;
		    if (window_top >= MAX_TOP) {
		        window_top = MAX_TOP;
		        dir = -1;
		    } else if (window_top <= MIN_TOP) {
		        window_top = MIN_TOP;
		        dir = 1;
		    }
		    $(window).scrollTop(window_top);
		    window.setTimeout(autoscroll, 100);
		}
		$("a.scrollTo").on('click', function(e) {
		    e.preventDefault();
		    var target = $(this).attr('href');
		    $('html, body').animate({
		       scrollTop: ($(target).offset().top)
		    }, 600); 
		});
		$('#quotesContainer').flexslider({
			animation: "slide",
			smoothHeight: true,
			directionNav: false,
			slideshow: true,
			selector: ".flexsliderQuotes > .quoteSingle"
		});
		$('.showForm .buttonShow').on("click", function() {
			$('.gfDiv').slideToggle(0, function() {
		    	$('.gfDiv .contentForm').show(0);
		    	$('.buttonShow').hide(0);
		  	});
		});
		$('#gform_submit_button_9').click(function(){
		    $('.gfDiv').show(0);
		    $('.buttonShow').hide(0);
		});
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
	    var dir = 1;
		var MIN_TOP = 200;
		var MAX_TOP = 350;
		function autoscroll() {
		    var window_top = $(window).scrollTop() + dir;
		    if (window_top >= MAX_TOP) {
		        window_top = MAX_TOP;
		        dir = -1;
		    } else if (window_top <= MIN_TOP) {
		        window_top = MIN_TOP;
		        dir = 1;
		    }
		    $(window).scrollTop(window_top);
		    window.setTimeout(autoscroll, 100);
		}
		$("a.scrollTo").on('click', function(e) {
		    e.preventDefault();
		    var target = $(this).attr('href');
		    $('html, body').animate({
		       scrollTop: ($(target).offset().top -110)
		    }, 600); 
		});
	  },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
